import React, { useCallback, useState } from "react"
import "../scss/index.scss"
// import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import Hero from "../components/hero/hero"
import HeroBanner from "../../static/img/_DSC0613.jpg"
import Image1 from "../../static/img/_DSC0609.jpg"
import Image2 from "../../static/img/_DSC0599.jpg"
import Image3 from "../../static/img/_DSC0608.jpg"
import Image4 from "../../static/img/_DSC0623.jpg"
import Image5 from "../../static/img/_DSC0613.jpg"
import Image6 from "../../static/img/_DSC0487.jpg"
import Image7 from "../../static/img/_DSC0488.jpg"
import Image8 from "../../static/img/_DSC0482.jpg"
import Double from "../components/layout/double"
import Quad from "../components/layout/quad"
import Footer from "../components/layout/footer"
import Icon5 from "../../static/icons/eco.png"
import Icon6 from "../../static/icons/ph.png"
import Icon7 from "../../static/icons/dog.png"
import Icon8 from "../../static/icons/biodegradable.png"
import { Helmet } from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha"
import GoogleReviews from "../components/google-reviews/google-reviews";
import ClientsCarousel from "../components/clients-carousel/clients-carousel";

// React gallery 
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

export default function Home() {
  const photos = [
    {
      src: Image6,
      width: 4,
      height: 3,
      alt: 'Eco wash solutions - chemical valves'
    }, {
      src: Image7,
      width: 4,
      height: 3,
      alt: 'Eco wash solutions - Chemical pump setup'
    },
    {
      src: Image8,
      width: 4,
      height: 3,
      alt: 'Eco wash solutions - steam cleaner'
    }
  ];
  
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return <>
  <Helmet>
    <meta charSet="utf-8" />
    <title>Eco Wash Solutions: Gutter, Driveway, Rooftop & More in Auckland</title>
    <description>Looking for professional soft washing and gutter cleaning services in Auckland? Eco Wash Solutions has got you covered! Contact us today for expert cleaning of gutters, driveways, rooftops, and more! Our eco-friendly solutions are safe for your property and the environment. </description>
    <link rel="canonical" href="https://ecocarwash.co.nz" />
    <meta name="google-site-verification" content="ADzuCyclOEqkFFB-5F3DGfrujbJ7o3aO78X-WDs9gKg" />
  </Helmet>
  <Header />
  <Layout>
  {/* Hero Block */}
  <Hero banner={HeroBanner} pretitle="Modern Edquipment" title="Property Wash" description="Anywhere Anytime We Come to You" />
  {/* Sites Linking */}
  {/* <Triple id="sites" align="center" type="noPadding">
    <a className="block-item one-third" href="#">
      Car Wash
    </a>
    <a className="block-item one-third" target="_blank" rel="noreferrer"  href="https://ecotruckwash.co.nz">
      Truck Wash
    </a>
    <a className="block-item one-third" target="_blank" rel="noreferrer"  href="https://ecopropertyservices.co.nz">
      Property Wash
    </a>
  </Triple> */}
   {/* Intro Block */}
      {/* Icons / Services */}
      {/* <Quad id="services" className="align--center">
    <div className="block-item a">
      <img className="icon-medium" src={Icon1} alt="Car Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Car</span> Grooming</h4>
    </div>
    <div className="block-item">
      <img className="icon-medium" src={Icon2} alt="Motorcycle Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Motorcycle</span> Grooming</h4>
  </div>
  <div className="block-item">
       <img className="icon-medium" src={Icon3} alt="Boat Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Boat</span> Grooming</h4>
  </div>
  <div className="block-item">
      <img className="icon-medium" src={Icon4} alt="Caravan / Motorhome Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Caravan</span> Grooming</h4>
  </div>
   </Quad> */}
   <Double align="left" type="light">
      <div className="double-block two-third" id="about1">
        <h6 className="sub-heading">Eco Wash Solutions</h6>
        <h2 className="heading">Anywhere Anytime We Come to You</h2>    
            {/* <AnchorLink to="/#contact" title="Contact Us" className="button button--large button--primary">
            Find out more..
            </AnchorLink> */}
      </div>
      <div className="double-block on-third">
        {/* <img src={Image1} alt="Eco Wash Solutions" title="Eco Wash Solutions" lazyload="true" className="ratings-image" width="100%" /> */}
      </div>
   </Double>
   <Double>
    <div className="double-block half" id="">
      <h3 className="heading heading--h4">Soft Washing</h3>
      <p>Soft washing is a highly effective and safe cleaning technique that is used to remove stubborn stains from building exteriors, including roofs. Unlike power washing, which can damage delicate surfaces like shingles, soft washing uses low pressure water and chemicals to break down and remove organic growth like mildew, algae, and bacteria. <br /><br />
          Soft washing process involves spraying a blend of chemicals onto the surface of the building or roof using a pressure washer, electric or 12Volt pump. The blend manifold ensures that the chemicals are applied in the right ratios to achieve the desired result, which can include a mixture of bleach, soap, and water. By using this method, we can effectively target and remove organic stains without causing damage to the underlying surface.<br /><br />
          When it comes to roof cleaning, soft washing is the preferred method we recommend. This is because the low pressure water and chemicals used in soft washing are gentle enough to avoid damaging the shingles, while still being effective at removing unsightly growth. In contrast, power washing can cause damage to shingles, leading to costly repairs.<br /><br />
          Overall, soft washing is a safe and effective cleaning technique that is ideal for removing stubborn organic stains from building exteriors, including roofs. Our team of experts use eco-friendly chemicals and advanced equipment to achieve optimal results, ensuring that your property is left looking clean and well-maintained.<br /><br />
      </p>
      </div>
    <div className="double-block half">
        <img src={Image1} alt="Eco Wash Solutions" title="Eco Wash Solutions" lazyload="true" width="100%" />
    </div>
   </Double>
   <Double reverseOnDesktop={true}>
    <div className="double-block half" id="">
      <h3 className="heading heading--h4">Roof Treatment</h3>
      <p>Roof treatment is an effective way to remove unsightly lichen and moss from rooftops, restoring their appearance and preventing damage caused by the growth of these organisms. Lichen and moss thrive in damp and shady conditions, which are common on many rooftops. If left untreated, they can cause significant damage to the roof and lead to leaks and other issues.<br /><br />
      Our roof treatment service uses a specialised product that is sprayed onto the roof surface, killing the lichen and moss. Over time, the dead organisms are gradually broken down and removed by natural weather conditions, such as rain and sun exposure. This process may take several months, but with patience and time, the roof will regain its original appearance and remain free of lichen and moss growth.<br /><br />
      It is important to note that while lichen and moss may not pose an immediate threat to the roof, they can eventually cause damage by trapping moisture and causing the roofing materials to deteriorate. This can lead to leaks and other problems that may be expensive to repair. Our roof treatment service provides an affordable and effective solution to prevent such damage and extend the life of your roof.<br /><br />
      We take pride in using eco-friendly products that are safe for the environment and do not harm your roof or surrounding landscaping. Our team of trained professionals will assess your roof and recommend the appropriate treatment to ensure optimal results. With our roof treatment service, you can enjoy a clean and attractive roof that adds value to your property.<br /><br />
      </p>
    </div>
    <div className="double-block half">
        <img src={Image2} alt="Eco Wash Solutions" title="Eco Wash Solutions" lazyload="true" width="100%" />
    </div>
   </Double>
   <Double>
    <div className="double-block half" id="">
      <h3 className="sub-heading sub-heading--basic">Gutter Cleaning</h3>
      <p>Gutter cleaning is an essential maintenance task that is often overlooked but is critical for protecting your property from water damage. Gutters are designed to channel rainwater away from your home, but when they become clogged with debris like leaves, twigs, and dirt, they can no longer function properly. This can lead to water damage to your roof, walls, and foundation.<br /><br />
      Gutter cleaning involves removing this debris to ensure that water can flow freely through the gutters and downspouts. Our team of professionals is trained to safely and efficiently clean gutters of all shapes and sizes. We use specialised tools and equipment to remove debris, including high-powered vacuums and pressure washers.<br /><br />
      It's important to have your gutters cleaned regularly, particularly during the fall when leaves and other debris can accumulate quickly. Neglecting this can lead to serious consequences, including water damage, mould growth, and even structural damage to your property.<br /><br />
      Our gutter cleaning services are designed to provide you with peace of mind, knowing that your gutters are clean and functioning properly. We offer flexible scheduling and competitive pricing to ensure that your gutter cleaning needs are met on your schedule and budget. Trust our team of experts to keep your property safe and dry with our professional gutter cleaning services.<br /><br />
      </p>
      </div>
    <div className="double-block half">
        <img src={Image3} alt="Eco Wash Solutions" title="Eco Wash Solutions" lazyload="true" width="100%" />
    </div>
   </Double>
   <Double reverseOnDesktop={true}>
    <div className="double-block half" id="">
      <h3 className="sub-heading sub-heading--basic">Driveway Cleaning</h3> 
      <p>Driveways are often one of the first things people notice when they arrive at your property. Over time, driveways can accumulate dirt, oil, and other debris, leaving unsightly stains and black mould that can be difficult to remove. That's where our driveway cleaning services come in.<br /><br />
      Our team of professionals uses specialised equipment and techniques to clean your driveway quickly and efficiently. We offer both hot and cold options to ensure that your driveway is cleaned thoroughly and effectively. Hot water is particularly effective at removing oil stains and other stubborn debris, while cold water is ideal for general cleaning and maintenance.<br /><br />
      Our driveway cleaning services are designed to improve the appearance of your property and extend the lifespan of your driveway. By removing dirt, oil, and other debris, we can help prevent damage to your driveway and ensure that it lasts for years to come.<br /><br />
      At our company, we use only the highest quality equipment and cleaning solutions to ensure that your driveway is cleaned safely and effectively. Our team of professionals is trained to handle any type of driveway, from concrete to asphalt, and we always take the necessary precautions to protect your property and the surrounding environment.<br /><br />
      If you're looking to improve the appearance of your property and extend the lifespan of your driveway, trust our team of experts to provide you with top-quality driveway cleaning services. Contact us today to schedule a cleaning and experience the difference for yourself.<br /><br />
      </p>
    </div>
    <div className="double-block half">
      <img src={Image4} alt="Eco Wash Solutions" title="Eco Wash Solutions" lazyload="true" width="100%" />
    </div>
   </Double>
   <Double>
    <div className="double-block half" id="">
      <h3 className="sub-heading sub-heading--basic">Mass/Mould treatment </h3>
      <p>Moss, mould, and lichen can not only be unsightly but can also cause damage to your roof decks and fences. Traditional methods of removing these organic stains often involve using harsh chemicals like bleach, which can be harmful to both the environment and the materials being cleaned. That's why our company offers a safe and effective moss/mould treatment that uses eco-friendly products to kill the unwanted growths without causing any damage to your property.<br /><br />
      Our moss/mould treatment process is designed to be gentle yet effective. We apply eco-friendly products to the affected areas to kill the moss, mould, and lichen, and then let nature take its course. Over time, the dead organic material will break down and get removed naturally by the elements, leaving your roof deck or fence looking clean and rejuvenated.<br /><br />
      Unlike traditional methods that use harsh chemicals, our eco-friendly products are safe for both your property and the environment. They do not contain any bleach or other harmful chemicals and are biodegradable, so you can feel good knowing that you are doing your part to protect the planet.<br /><br />
      We understand that every property is unique, and that's why we customise our moss/mould treatment to fit your specific needs. Whether you need a one-time treatment or regular maintenance, our team of professionals will work with you to create a plan that works for you and your property.<br /><br />
      If you're looking for a safe and effective way to remove moss, mould, and lichen from your roof deck or fence, look no further than our moss/mould treatment services. Contact us today to learn more about our services and schedule a treatment for your property.<br /><br />
      </p>
    </div>
    <div className="double-block half">
      <img src={Image5} alt="Eco Wash Solutions" title="Eco Wash Solutions" lazyload="true" width="100%" />
    </div>
   </Double>
   {/* Icons / Services */}
    {/* Our Setup Block */}
    <div className="equipment-block container--medium container--centered">
        <div className="container">
          <h2 className="heading align--center">Our equipment</h2>
          <Gallery photos={photos} onClick={openLightbox} columns={3} direction="column" />
          <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        </div>
    </div>   
    {/* About Block */}
    {/* <div className="pricing-block container--dark container--centered" id="about">
    <div className="container container--narrow">
    <h2 className="heading align--center">About</h2>
    <h6 className="sub-heading sub-heading--basic align--center">What we do..</h6>
    <p>Eco Wash Solutions has been delivering results since its opened in 2013. Our goal is to provide both a superior customer service experience and tremendous value for our customers.</p>
    <p>Eco Wash Solutions has good experience in Mobile Car Wash Service and we are the best mobile car groomer in Auckland. Our valued customers provide us 5star review on our Facebook page. We are also passionate about exceeding customers expectations.</p>
    <p>Eco Wash Solutions and Detail services from Daily driven vehicle, Luxury , Luxury sports , Classic car to Caravans, Boats and Motorcycles</p>
    <p>Eco Wash Solutions and Detail provide skilled and experienced car valet services for your car. We can easily fit in your schedule, wherever you are, in office or home.</p>
    <p>Eco Wash Solutions has highly competitive prices list below . You can choose one of them or if you not sure about any detail we are happy to assist you over the phone or fill up simple contact form below and we will get in touch.</p>
    </div>
    </div> */}
   {/* Pricing Block */} 
   {/* <PriceBlock /> */}

       {/* Testimonials - To be added in future */}
    <div className="pricing-block container--dark container--centered" id="reviews">
        <div className="container">
            <h2 className="heading align--center">Google Reviews</h2>
            <h6 className="sub-heading sub-heading--basic align--center">What our clients say...</h6>
            <GoogleReviews/>
        </div>
    </div>
   <div className="container">
   </div>
   <Quad id="" className="align--center">
    <div className="block-item a">
      <img className="icon-medium" src={Icon5} alt="Car Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Eco</span> Friendly</h4>
    </div>
    <div className="block-item">
      <img className="icon-medium" src={Icon6} alt="Motorcycle Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>PH</span> Neutral</h4>
  </div>
  <div className="block-item">
       <img className="icon-medium" src={Icon7} alt="Boat Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Pet</span> Safe</h4>
  </div>
  <div className="block-item">
      <img className="icon-medium" src={Icon8} alt="Caravan / Motorhome Grooming - Eco Wash Solutions" loading="lazy" />
      <h4><span>Biodegradable</span></h4>
  </div>
   </Quad>
   {/* <Double align="left" type="bgLight">
   <div className="double-block two-third" id="about1">
      <h6 className="sub-heading">Experienced &amp; Equipped to Tackle your business needs</h6>
      <h2 className="heading">Mobile Fleet Washing</h2>
      <p>Having a sparkling clean fleet of vehicles can help amplify your public image while improving safety. We can help extend the vehicle’s life by removing damaging materials from the vehicle, utilising our extensive fleet service.<br /><br />
      To find out what we can offer for you, please give us a call at 09 215 4117 or email us at info@ecowashsolutions.co.nz .</p>
      </div>
   </Double>    */}

    {/* Testimonials - To be added in future */}
    <div className="pricing-block container--bgLight container--centered" id="testimonials">
     <div className="container">
     <h2 className="heading align--center">Some of our clients</h2>
     {/* <h6 className="sub-heading sub-heading--basic align--center">What our clients say...</h6> */}
     <ClientsCarousel />
     </div>
  </div>

   {/* Contact Block */}
   <div className="contact-block container container--narrow" id="contact">
     <div className="double-block">
       <h6  className="sub-heading">Get in touch</h6>
       <h2 className="heading">Contact Us</h2>
      <p>For any enquiries regarding our services, please contact at details below or get in touch via our contact form.</p>
      {/* <h4>Auckland</h4>
      <p>M: <a className="styled-link" href="tel:021 724 555">021 724 555</a><br />
         P: <a className="styled-link" href="tel:09 215 4117">09 215 4117</a><br />
        E: <a className="styled-link" href="mailto:info@ecowashsolutions.co.nz">info@ecowashsolutions.co.nz</a>
        </p>

        <h4>Hamilton</h4>
      <p>P: <a className="styled-link" href="tel:0800 758 575"> 0800 758 575</a><br />
        M: <a className="styled-link" href="tel:021 275 8575">021 275 8575</a><br />
         E: <a className="styled-link" href="mailto:hamilton@ecowashsolutions.co.nz">hamilton@ecowashsolutions.co.nz</a>
        </p> */}
        
      <form method="post" data-netlify="true" data-netlify-recaptcha="true" name="contact" className="contact-form form">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <label className="form-field form-field--50">
        <input type="text" name="name" id="name" className="form-input" placeholder="Name" />
      </label>
      <label className="form-field form-field--50">
        <input type="email" name="email" id="email" className="form-input" placeholder="Email" />
      </label>
      <label className="form-field form-field--50">
        <input type="text" name="phone" id="phone" className="form-input" placeholder="Phone Number" />
      </label>
      <label className="form-field form-field--50">
        <input type="text" name="subject" id="subject" className="form-input" placeholder="Subject" />
      </label>
      <div className="inline-radio form-field">
      <p className="field-label">Preferred mode of contact</p>
        <label>
          <input type="radio" name="contact-mode" id="radio1" value="email" />
          Email
        </label>
        <label>
          <input type="radio" name="contact-mode" id="radio2" value="phone" />
          Phone
        </label>
      </div>
      <label className="form-field">
        <textarea name="message" className="form-input" id="message" rows="5" placeholder="Message" />
      </label>
      <div className="form-field">
        <ReCAPTCHA sitekey="6LcRZSYTAAAAADRrX8Y62ze118p0kaWhbXJZ0K9v" />
        <br />
        <button type="submit" className="button button--large button--primary">Send</button>
        <button type="reset" className="button button--large button--secondary">Clear</button>
      </div>
      </form>
     </div>
  </div>

  </Layout>
  <Footer copyright="Copyright &copy; 2020 - 2022 Eco Wash Solutions. All Rights Reserved" />
  {/* <FloatingNotice>
    <h5>Covid 19 notice</h5>
    <p>All vehicle cleaning services now includes sanitizing before and after your car groom to protect you and our groomers.</p>
  </FloatingNotice> */}
  </>
}
